export const columnsDataCheck = [
  {
    Header: "Call Sign",
    accessor: "name",
  },
  {
    Header: "Gate/Bays",
    accessor: "progress",
  },
  {
    Header: "ATA",
    accessor: "quantity",
  },
  // {
  //   Header: "Status",
  //   accessor: "date",
  // },
];
export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];
