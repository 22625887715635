import React, { useState } from 'react'
import Card from "components/card/Card";
import {
    Box,
    Flex,
    Grid,
    Link,
    Text,
    useColorModeValue,
    SimpleGrid,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
  } from "@chakra-ui/react";
  import DatePicker from "react-datepicker";
  import { Table, Pagination, Spin, Modal } from "antd";
  import { Button } from "@chakra-ui/react";



const Reports = () => {
    const [userObj, setUserObj] = useState({});
    const [loading, setLoading] = useState(true);
    // const [dataSource, setDataSource] = useState();
    const [selectedDate, setSelectedDate] = useState(new Date()); // Initialize with current date
    console.log(userObj);
    const handleDateChange = (date) => {
      setSelectedDate(date);
    };


    const columns = [
        {
          title: "Gates/Bay",
          dataIndex: "Gates_Bay",
          key: "Gates_Bay",
        },
        {
          title: "Aircraft Type",
          dataIndex: "aircraft_type",
          key: "aircraft_type",
        },
        {
          title: "AC Registrations",
          dataIndex: "ac-registrations",
          key: "ac-registrations",
        },
        {
          title: "Destinations",
          dataIndex: "destinations",
          key: "destinations",
        },
        {
            title: "Flight Type",
            dataIndex: "flight_Type",
            key: "flight_Type",
          },
      ];
      const dataSource = [
        {
          key: '1',
          Gates_Bay: 'A1',
          aircraft_type: 'G650',
          'ac-registrations': 'N724CL',
          destinations: 'Kyoto, Japan',
          flight_Type: 'Local'
        },
        {
          key: '2',
          Gates_Bay: 'C3',
          aircraft_type: 'A330',
          'ac-registrations': 'G-BYFA',
          destinations: 'Reykjavik, Iceland',
          flight_Type: 'International'
        },
        // Add more data items as needed
      ];

      const [searchText, setSearchText] = useState('');

      const handleSearch = () => {
        // Perform search logic using searchText state
        console.log('Search button clicked. Search text:', searchText);
      };
    

  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        spacing={{ base: "20px", xl: "120px" }}
        ml={{ base: "0", md: "0", xl: "5" }}
        mt="10"
      >
        <Card className="row" direction="column" w="100%" px="60px">
          {/* Your content */}
          <div className='row'> 
          <div className="col-md-6">
                <FormControl>
                  <FormLabel htmlFor="component-simple2">Search  Fight Type</FormLabel>
                  <select
                    id="component-simple2"
                    className="form-control"
                    placeholder="Select A/C Type"
                    // onChange={(e) => handleFieldChange('aircraftType', e.target.value)}
                    onChange={(e) =>
                      setUserObj({ ...userObj, aircraftType: e.target.value })
                    }
                  >
                    <option disabled>Select Fight Type</option>
                    <option>None</option>
                  </select>
                </FormControl>
                
              </div>
              <div className="col-md-6">
                <FormControl>
                  <FormLabel htmlFor="component-simple2">Search By Aircraft Type</FormLabel>
                  <select
                    id="component-simple2"
                    className="form-control"
                    placeholder="Select A/C Type"
                    // onChange={(e) => handleFieldChange('aircraftType', e.target.value)}
                    onChange={(e) =>
                      setUserObj({ ...userObj, aircraftType: e.target.value })
                    }
                  >
                    <option disabled>Select Aircraft Type</option>
                    <option>None</option>
                  </select>
                </FormControl>
                
              </div>
              </div>
              <div className='row'> 
              <div className="col-md-6">
              <FormLabel htmlFor="componentdate">From-Date</FormLabel>
                <DatePicker
        id="componentdate"
        selected={selectedDate}
        onChange={handleDateChange}
        className="form-control"
        dateFormat="dd/MM/yyyy"
      />
              </div>
              <div className="col-md-6">
              <FormLabel htmlFor="componentdate">To-Date</FormLabel>
                <DatePicker
        id="componentdate"
        selected={selectedDate}
        onChange={handleDateChange}
        className="form-control"
        dateFormat="dd/MM/yyyy"
      />
              </div>
              <Flex justify="flex-end">
      <Button
        variant="outline"
        color="white"
        bg="#A21D20"
        _hover={{ bg: "#A21D20" }}
        marginTop="20px"
        onClick={handleSearch}
      >
        Search
      </Button>
    </Flex>

              
              </div>
              <Card>
              <Table
      dataSource={dataSource}
      columns={columns}
    />
             </Card>
        </Card>
      </SimpleGrid>
    </Box>
  )
}

export default Reports; // Export the component as default
